import React, { createContext, useContext, useState, useCallback } from 'react';

interface AuthModalContextType {
  showAuthModal: (returnUrl?: string) => void;
  hideAuthModal: () => void;
  isAuthModalVisible: boolean;
  returnUrl: string | undefined;
}

const AuthModalContext = createContext<AuthModalContextType | undefined>(undefined);

export const AuthModalProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [returnUrl, setReturnUrl] = useState<string>();

  const showAuthModal = useCallback((returnUrl?: string) => {
    setReturnUrl(returnUrl);
    setIsVisible(true);
  }, []);

  const hideAuthModal = useCallback(() => {
    setIsVisible(false);
    setReturnUrl(undefined);
  }, []);

  return (
    <AuthModalContext.Provider
      value={{
        showAuthModal,
        hideAuthModal,
        isAuthModalVisible: isVisible,
        returnUrl
      }}
    >
      {children}
    </AuthModalContext.Provider>
  );
};

export const useAuthModal = () => {
  const context = useContext(AuthModalContext);
  if (!context) {
    throw new Error('useAuthModal must be used within an AuthModalProvider');
  }
  return context;
};