import { createContext, useContext } from 'react';
import type { ApiMethods } from '../services/api';

export const ApiContext = createContext<ApiMethods | null>(null);

export const ApiProvider = ApiContext.Provider;

export const useApi = () => {
  const context = useContext(ApiContext);
  if (!context) {
    throw new Error('useApi must be used within an ApiProvider');
  }
  return context;
}; 