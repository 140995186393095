import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { AuthModalProvider } from '@saintly-whispers/shared/src/contexts/AuthModalContext';
import { PrayerPlanProvider } from '@saintly-whispers/shared/src/contexts/PrayerPlanContext';

if (process.env.REACT_APP_REMOVE_CONSOLE === 'true') {
  console.log = () => {};
  console.info = () => {};
  console.debug = () => {};
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <AuthModalProvider>
    <PrayerPlanProvider>
      <App />
    </PrayerPlanProvider>
  </AuthModalProvider>
);

reportWebVitals();
