import { useState, useEffect, useCallback } from 'react';
import { User, getAuth, sendPasswordResetEmail } from 'firebase/auth';
import { 
  doc, 
  onSnapshot, 
  setDoc, 
  updateDoc, 
  DocumentReference, 
  Timestamp,
  getFirestore 
} from 'firebase/firestore';
import { debounce } from 'lodash';
import { UserData, UseUserDataReturn, AuthResult } from '../../types/auth';
import { DebouncedFunc } from 'lodash';
import { FIRESTORE_PATHS } from '../../services/api/apiConfig';

interface UseUserDataHook extends UseUserDataReturn {
  updateUserData: DebouncedFunc<(userId: string, data: Partial<UserData>) => Promise<void>>;
  resetPassword: (email: string) => Promise<AuthResult>;
}

export const useUserData = (): UseUserDataHook => {
  const auth = getAuth();
  const db = getFirestore();
  const [userData, setUserData] = useState<UserData | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [authChecked, setAuthChecked] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const updateUserData: DebouncedFunc<(userId: string, data: Partial<UserData>) => Promise<void>> = useCallback(
    debounce(async (userId: string, data: Partial<UserData>): Promise<void> => {
      try {
        const userDocRef = doc(db, FIRESTORE_PATHS.USERS.path(userId));
        await updateDoc(userDocRef, data);
      } catch (err) {
        console.error("Error updating user data:", err);
        setError(`Error updating user data: ${(err as Error).message}`);
      }
    }, 500),
    [db]
  );

  const resetPassword = async (email: string): Promise<AuthResult> => {
    try {
      await sendPasswordResetEmail(auth, email);
      return { 
        success: true, 
        message: 'Password reset email sent. Check your inbox.',
        error: null
      };
    } catch (error) {
      return { 
        success: false, 
        error: (error as Error).message,
        message: null
      };
    }
  };

  useEffect(() => {
    let unsubscribe = () => {};

    const fetchUserData = async (user: User) => {
      if (!user) {
        setUserData(null);
        setLoading(false);
        return;
      }

      try {
        const userDocRef: DocumentReference = doc(db, FIRESTORE_PATHS.USERS.path(user.uid));
        unsubscribe = onSnapshot(userDocRef, 
          async (doc) => {
            if (doc.exists()) {
              setUserData(doc.data() as UserData);
            } else {
              const newUserData: UserData = {
                uid: user.uid,
                email: user.email || '',
                isAnonymous: user.isAnonymous,
                isAdmin: false,
                createdAt: Timestamp.now(),
                lastLogin: Timestamp.now()
              };
              try {
                await setDoc(userDocRef, newUserData);
                setUserData(newUserData);
              } catch (err) {
                console.error("Error creating user document:", err);
                setError(`Error creating user document: ${(err as Error).message}`);
              }
            }
            setLoading(false);
          },
          (err) => {
            console.error("Error fetching user data:", err);
            setError(`Error fetching user data: ${err.message}`);
            setLoading(false);
          }
        );
      } catch (err) {
        console.error("Error in fetchUserData:", err);
        setError(`Error in fetchUserData: ${(err as Error).message}`);
        setLoading(false);
      }
    };

    const authUnsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        fetchUserData(user);
      } else {
        setUserData(null);
        setLoading(false);
      }
    });

    return () => {
      authUnsubscribe();
      unsubscribe();
    };
  }, []);

  return { userData, loading, error, updateUserData, resetPassword };
};
