import { Firestore } from 'firebase/firestore';
import { Auth, User } from 'firebase/auth';
import { FirebaseStorage } from 'firebase/storage';
import { Analytics } from 'firebase/analytics';
import { Functions } from 'firebase/functions';

export abstract class BaseFirebaseService {
  abstract firestore: Firestore;
  abstract auth: Auth;
  abstract storage: FirebaseStorage;
  abstract functions: Functions;
  analytics?: Analytics;

  abstract getAuthToken(): Promise<string | null>;
  abstract signInAnonymously(): Promise<User>;
  abstract initializeApp(): Promise<void>;
}
