import { createContext, useContext } from 'react';
import { BaseFirebaseService } from '../services/firebase/base';

const FirebaseContext = createContext<BaseFirebaseService | null>(null);

export const useFirebase = () => {
  const context = useContext(FirebaseContext);
  if (!context) {
    throw new Error('useFirebase must be used within a FirebaseProvider');
  }
  return context;
};

export const FirebaseProvider = FirebaseContext.Provider;