import React, { FC, PropsWithChildren, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useUserData } from '@saintly-whispers/shared/src/hooks/user/useUserData';
import { useAuthModal } from '@saintly-whispers/shared/src/contexts/AuthModalContext';
import LoadingSpinner from '../common/LoadingSpinner';
import { UseUserDataHook } from '@shared/types/auth';

interface ProtectedRouteProps extends PropsWithChildren {
  isAdminRoute?: boolean;
}

export const ProtectedRoute: FC<ProtectedRouteProps> = ({ 
  children, 
  isAdminRoute = false 
}) => {
  const { userData, loading } = useUserData() as UseUserDataHook;
  const { showAuthModal } = useAuthModal();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!loading) {
      if (!userData || userData.isAnonymous) {
        showAuthModal(location.pathname);
      } else if (isAdminRoute && !userData.isAdmin) {
        navigate('/', { replace: true });
      }
    }
  }, [userData, loading, isAdminRoute, location.pathname, showAuthModal, navigate]);

  if (loading) return <LoadingSpinner />;
  if (!userData || userData.isAnonymous || (isAdminRoute && !userData.isAdmin)) return null;

  return <>{children}</>;
};