import { 
  User,
  UserCredential,
  createUserWithEmailAndPassword, 
  signInWithEmailAndPassword, 
  signInAnonymously, 
  signOut as firebaseSignOut, 
  linkWithCredential, 
  EmailAuthProvider, 
  sendPasswordResetEmail,
  AuthError,
  GoogleAuthProvider,
  signInWithPopup
} from "firebase/auth";
import { doc, setDoc, Timestamp } from 'firebase/firestore';
import { useFirebase } from '../contexts/FirebaseContext';
import { AuthResult } from '../types/auth';
import { checkRateLimit, RateLimitError } from './rateLimiting';

export { EmailAuthProvider, linkWithCredential } from 'firebase/auth';

export const useAuthentication = () => {
  const { auth, firestore } = useFirebase();

  const createUserDocument = async (user: User): Promise<void> => {
    if (!user) return;

    const userRef = doc(firestore, 'users', user.uid);
    const now = Timestamp.now();
    
    try {
      await setDoc(userRef, {
        email: user.email,
        isAnonymous: user.isAnonymous,
        uid: user.uid,
        createdAt: now,
        lastLogin: now,
      }, { merge: true });
    } catch (error) {
      console.error("Error creating user document:", error);
      throw error;
    }
  };

  const signOut = (): Promise<void> => firebaseSignOut(auth);

  const getAuthToken = async (): Promise<string | null> => {
    const user = auth.currentUser;
    if (user) {
      return user.getIdToken();
    }
    return null;
  };

  const linkAnonymousAccount = async (user: User, email: string, password: string): Promise<void> => {
    const credential = EmailAuthProvider.credential(email, password);
    try {
      await linkWithCredential(user, credential);
    } catch (error) {
      throw error;
    }
  };

  const signUp = async (email: string, password: string): Promise<AuthResult> => {
    try {
      const userCredential: UserCredential = await createUserWithEmailAndPassword(auth, email, password);
      await createUserDocument(userCredential.user);
      return { 
        success: true, 
        user: userCredential.user,
        error: null,
        message: null
      };
    } catch (error) {
      let errorMessage = "An error occurred during sign up.";
      if ((error as AuthError).code === 'auth/email-already-in-use') {
        errorMessage = "This email is already in use. Please try logging in or use a different email.";
      } else if ((error as AuthError).code === 'auth/weak-password') {
        errorMessage = "Password is too weak. Please use a stronger password.";
      }
      return { 
        success: false, 
        error: errorMessage,
        message: null 
      };
    }
  };

  const logIn = async (email: string, password: string): Promise<AuthResult> => {
    try {
      await checkRateLimit(`login-${email}`);
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      return {
        success: true,
        user: userCredential.user,
        message: null,
        error: null
      };
    } catch (error) {
      let errorMessage = "Invalid email or password.";
      
      if (error instanceof RateLimitError) {
        errorMessage = "Too many login attempts. Please try again in 15 minutes or use 'Forgot Password'.";
      } else if (error instanceof Error && 
                ((error as AuthError).code === 'auth/user-not-found' || 
                 (error as AuthError).code === 'auth/wrong-password')) {
        errorMessage += " Please try again or click 'Sign Up' if you need to create an account.";
      }
      
      return { 
        success: false, 
        error: errorMessage,
        message: null
      };
    }
  };

  const resetPassword = async (email: string): Promise<AuthResult> => {
    try {
      await sendPasswordResetEmail(auth, email);
      return {
        success: true,
        message: "Password reset email sent. Please check your inbox.",
        error: null
      };
    } catch (error) {
      let errorMessage = "Failed to send password reset email.";
      if ((error as AuthError).code === 'auth/user-not-found') {
        errorMessage = "No account found with this email address.";
      }
      return {
        success: false,
        error: errorMessage,
        message: null
      };
    }
  };

  const signInWithGoogle = async (): Promise<AuthResult> => {
    try {
      const provider = new GoogleAuthProvider();
      const userCredential = await signInWithPopup(auth, provider);
      const user = userCredential.user;
      await createUserDocument(user);
      
      return {
        success: true,
        user,
        message: null,
        error: null
      };
    } catch (error) {
      let errorMessage = "Failed to sign in with Google. Please try again.";
      if ((error as AuthError).code === 'auth/popup-closed-by-user') {
        errorMessage = "Sign in was cancelled. Please try again.";
      }
      
      return {
        success: false,
        error: errorMessage,
        message: null
      };
    }
  };

  const signInAnonymouslyIfNeeded = async (): Promise<AuthResult> => {
    if (!auth.currentUser) {
      try {
        const userCredential = await signInAnonymously(auth);
        await createUserDocument(userCredential.user);
        return {
          success: true,
          user: userCredential.user,
          message: null,
          error: null
        };
      } catch (error) {
        return {
          success: false,
          error: "Failed to sign in anonymously",
          message: null
        };
      }
    }
    return {
      success: true,
      user: auth.currentUser,
      message: null,
      error: null
    };
  };

  return {
    signOut,
    signUp,
    logIn,
    resetPassword,
    signInWithGoogle,
    signInAnonymouslyIfNeeded,
    createUserDocument,
    getAuthToken,
    linkAnonymousAccount,
    auth
  };
};
