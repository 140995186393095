import { BaseFirebaseService } from '../firebase/base';
import { createApi } from './createApi';
import { createApiMethods } from './methods';
import type { ApiMethods } from './methods';

export const initializeApi = (firebaseService: BaseFirebaseService): ApiMethods => {
  const axiosInstance = createApi(firebaseService);
  return createApiMethods(axiosInstance);
};

export type { ApiMethods }; 