import React, { useState } from 'react';
import AuthComponent from './AuthComponent';
import Modal from 'react-bootstrap/Modal';
import Nav from 'react-bootstrap/Nav';

interface AuthModalProps {
  show: boolean;
  onHide: () => void;
  returnUrl?: string;
}

const AuthModal: React.FC<AuthModalProps> = ({ show, onHide, returnUrl }) => {
  const [isLogin, setIsLogin] = useState(false);

  const handleModeChange = (mode: boolean) => {
    setIsLogin(mode);
  };

  const handleTabClick = (mode: boolean) => {
    setIsLogin(mode);
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      centered
      size="lg"
      backdrop="static"
      className="auth-modal"
      dialogClassName="modal-dialog-centered"
    >
      <Modal.Header closeButton className="auth-modal-header">
        <Nav variant="tabs" className="auth-tabs">
          <Nav.Item>
            <Nav.Link 
              active={isLogin} 
              onClick={() => handleTabClick(true)}
              className="auth-tab"
            >
              Login
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link 
              active={!isLogin} 
              onClick={() => handleTabClick(false)}
              className="auth-tab"
            >
              Sign Up
            </Nav.Link>
          </Nav.Item>
        </Nav>
      </Modal.Header>
      <Modal.Body>
        <AuthComponent 
          isModal={true} 
          returnUrl={returnUrl} 
          onSuccess={onHide}
          defaultIsLogin={isLogin}
          onAuthModeChange={handleModeChange}
        />
      </Modal.Body>
    </Modal>
  );
};

export default AuthModal;
