import React from 'react';
import { Link } from 'react-router-dom';
import '../../styles/footer.css';

const Footer: React.FC = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="footer">
      <div className="footer-content">
        <nav className="footer-nav">
          <Link to="/about" className="footer-link">About</Link>
          <Link to="/terms" className="footer-link">Terms</Link>
        </nav>
        <p className="copyright">
          &copy; {currentYear} Saintly Whispers
        </p>
      </div>
    </footer>
  );
};

export default Footer;
