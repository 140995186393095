import React, { useEffect, useRef } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import { Book, Home, Search, LibraryBig, User, Crown, Sparkles } from 'lucide-react';
import { NavbarProps } from '../../types/AppTypes';
import { useAuthModal } from '@saintly-whispers/shared/src/contexts/AuthModalContext';
import styles from '../../styles/Navbar.module.css';

const Navbar: React.FC<NavbarProps> = ({ user, userData }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const today = format(new Date(), 'yyyy-MM-dd');
  const navbarRef = useRef<HTMLElement | null>(null);
  const { showAuthModal } = useAuthModal();

  const isActive = (path: string): boolean => {
    if (path === '/') {
      return location.pathname === path;
    }
    return location.pathname.startsWith(path);
  };

  const handleLinkClick = (e: React.MouseEvent<HTMLAnchorElement>, to: string): void => {
    e.preventDefault();
    closeNavbar();
    navigate(to);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent): void => {
      if (navbarRef.current && !navbarRef.current.contains(event.target as Node)) {
        closeNavbar();
      }
    };

    const handleKeyDown = (event: KeyboardEvent): void => {
      if (event.key === 'Tab') {
        setTimeout(() => {
          if (navbarRef.current && !navbarRef.current.contains(document.activeElement)) {
            closeNavbar();
          }
        }, 0);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const closeNavbar = (): void => {
    const navbarToggler = document.querySelector('.navbar-toggler') as HTMLElement;
    const navbarCollapse = document.querySelector('.navbar-collapse');
    if (navbarCollapse?.classList.contains('show')) {
      navbarToggler?.click();
    }
  };

  useEffect(() => {
    const navLinks = document.querySelectorAll('.nav-link');
    navLinks.forEach(link => {
      link.addEventListener('click', closeNavbar);
    });

    return () => {
      navLinks.forEach(link => {
        link.removeEventListener('click', closeNavbar);
      });
    };
  }, []);

  const handleAuthClick = (e: React.MouseEvent<HTMLAnchorElement>): void => {
    e.preventDefault();
    if (!userData || userData.isAnonymous) {
      showAuthModal(location.pathname);
    } else {
      navigate('/account');
    }
  };

  return (
    <nav className={`navbar navbar-expand-lg fixed-top ${styles.navbar}`} ref={navbarRef}>
      <div className="container">
        <Link className={styles.brand} to="/">
          <img src="https://firebasestorage.googleapis.com/v0/b/lofh-dbbbb.appspot.com/o/img%2Fsite-images%2Flogo-square.jpg?alt=media" 
               alt="Logo" 
               className={`${styles.logo} me-2`} 
          />
          Saintly Whispers
        </Link>
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className={`collapse navbar-collapse ${styles.collapse}`} id="navbarNav">
          <ul className={`navbar-nav ms-auto ${styles.navList}`}>
            <li className="nav-item">
              <Link 
                className={`${styles.link} ${isActive('/') ? styles.active : ''}`} 
                to="/" 
                onClick={(e) => handleLinkClick(e, '/')}
              >
                <Home size={18} className={styles.icon} />
                Home
              </Link>
            </li>
            <li className="nav-item">
              <Link 
                className={`${styles.link} ${isActive('/prayers') ? styles.active : ''}`} 
                to="/catalog" 
                onClick={(e) => handleLinkClick(e, '/prayers')}
              >
                <Search size={18} className={styles.icon} />
                Catalog
              </Link>
            </li>
            <li className="nav-item">
              <Link 
                className={`${styles.link} ${isActive('/series') ? styles.active : ''}`} 
                to="/series" 
                onClick={(e) => handleLinkClick(e, '/series')}
              >
                <LibraryBig size={18} className={styles.icon} />
                Series
              </Link>
            </li>
            <li className="nav-item">
              <Link 
                className={`${styles.link} ${isActive('/saints') ? styles.active : ''}`} 
                to="/saints" 
                onClick={(e) => handleLinkClick(e, '/saints')}
              >
                <Crown size={18} className={styles.icon} />
                Saints
              </Link>
            </li>
            <li className="nav-item">
              <Link 
                className={`${styles.link} ${isActive('/scripture') ? styles.active : ''}`} 
                to="/scripture" 
                onClick={(e) => handleLinkClick(e, '/scripture')}
              >
                <Book size={18} className={styles.icon} />
                Scripture
              </Link>
            </li>
            <li className="nav-item">
              <Link 
                className={`${styles.link} ${isActive('/advisor') ? styles.active : ''}`} 
                to="/advisor" 
                onClick={(e) => handleLinkClick(e, '/advisor')}
              >
                <Sparkles size={18} className={styles.icon} />
                Prayer Advisor
              </Link>
            </li>
            <li className="nav-item">
              {!userData?.isAnonymous ? (
                <Link 
                  className={`${styles.link} ${isActive('/journey') ? styles.active : ''}`} 
                  to="/journey" 
                  onClick={(e) => handleLinkClick(e, '/journey')}
                >
                  <User size={18} className={styles.icon} />
                  My Journey
                </Link>
              ) : (
                <a 
                  href="#"
                  className={`${styles.link} ${isActive('/account') ? styles.active : ''}`} 
                  onClick={handleAuthClick}
                >
                  <User size={18} className={styles.icon} />
                  Sign In
                </a>
              )}
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
