import { initializeApp, FirebaseApp } from 'firebase/app';
import { getAuth, signInAnonymously, Auth, User } from 'firebase/auth';
import { getFirestore, Firestore } from 'firebase/firestore';
import { getStorage, FirebaseStorage } from 'firebase/storage';
import { getAnalytics, Analytics } from 'firebase/analytics';
import { getFunctions, Functions, connectFunctionsEmulator } from 'firebase/functions';
import { BaseFirebaseService } from '@saintly-whispers/shared/src/services/firebase/base';

interface FirebaseConfig {
  apiKey: string;
  authDomain: string;
  projectId: string;
  storageBucket: string;
  messagingSenderId: string;
  appId: string;
  measurementId?: string;
}

export class WebFirebaseService extends BaseFirebaseService {
  firestore: Firestore;
  auth: Auth;
  storage: FirebaseStorage;
  analytics: Analytics;
  functions: Functions;
  private app: FirebaseApp;

  constructor(config: FirebaseConfig) {
    super();
    this.app = initializeApp(config);
    this.auth = getAuth(this.app);
    this.firestore = getFirestore(this.app);
    this.storage = getStorage(this.app);
    this.analytics = getAnalytics(this.app);
    this.functions = getFunctions(this.app);

    if (process.env.NODE_ENV === 'development') {
      connectFunctionsEmulator(this.functions, 'localhost', 5001);
    }
  }

  async initializeApp(): Promise<void> {
    // This method exists to satisfy the abstract class requirement
    // Actual initialization happens in constructor
    return Promise.resolve();
  }

  async getAuthToken(): Promise<string | null> {
    return this.auth.currentUser?.getIdToken() || null;
  }

  async signInAnonymously(): Promise<User> {
    if (!this.auth.currentUser) {
      const userCredential = await signInAnonymously(this.auth);
      console.log('Signed in anonymously');
      return userCredential.user;
    }
    console.log('User already signed in:', this.auth.currentUser.isAnonymous ? 'anonymously' : 'with account');
    return this.auth.currentUser;
  }

  async signInAnonymouslyIfNeeded(): Promise<User> {
    return this.signInAnonymously();
  }
} 