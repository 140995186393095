import { initializeApp } from 'firebase/app';
import { getAuth, signInAnonymously, Auth, User } from 'firebase/auth';
import { getFirestore, Firestore } from 'firebase/firestore';
import { getStorage, FirebaseStorage } from 'firebase/storage';
import { getAnalytics, Analytics } from "firebase/analytics";
import { connectFunctionsEmulator, getFunctions, Functions } from 'firebase/functions';
import { BaseFirebaseService } from '@saintly-whispers/shared/src/services/firebase/base';

const isProduction = process.env.REACT_APP_NODE_ENV === 'production';

interface FirebaseConfig {
  apiKey: string;
  authDomain: string;
  projectId: string;
  storageBucket: string;
  messagingSenderId: string;
  appId: string;
  measurementId?: string;
}

export class WebFirebaseService extends BaseFirebaseService {
  firestore: Firestore;
  auth: Auth;
  storage: FirebaseStorage;
  analytics: Analytics;
  functions: Functions;
  private app;

  constructor() {
    super();
    this.app = this.initializeApp();
    this.auth = getAuth(this.app);
    this.firestore = getFirestore(this.app);
    this.storage = getStorage(this.app);
    this.analytics = getAnalytics(this.app);
    this.functions = getFunctions(this.app);

    if (process.env.NODE_ENV === 'development') {
      connectFunctionsEmulator(this.functions, 'localhost', 5001);
    }
  }

  initializeApp(): any {
    const firebaseConfig: FirebaseConfig = isProduction
      ? {
          apiKey: process.env.REACT_APP_FIREBASE_API_KEY!,
          authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN!,
          projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID!,
          storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET!,
          messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID!,
          appId: process.env.REACT_APP_FIREBASE_APP_ID!
        }
      : {
          apiKey: process.env.REACT_APP_DEV_FIREBASE_API_KEY!,
          authDomain: process.env.REACT_APP_DEV_FIREBASE_AUTH_DOMAIN!,
          projectId: process.env.REACT_APP_DEV_FIREBASE_PROJECT_ID!,
          storageBucket: process.env.REACT_APP_DEV_FIREBASE_STORAGE_BUCKET!,
          messagingSenderId: process.env.REACT_APP_DEV_FIREBASE_MESSAGING_SENDER_ID!,
          appId: process.env.REACT_APP_DEV_FIREBASE_APP_ID!,
          measurementId: process.env.REACT_APP_DEV_FIREBASE_MEASUREMENT_ID
        };
    
    return initializeApp(firebaseConfig);
  }

  async getAuthToken(): Promise<string | null> {
    return this.auth.currentUser?.getIdToken() || null;
  }

  async signInAnonymously(): Promise<User> {
    if (!this.auth.currentUser) {
      const userCredential = await signInAnonymously(this.auth);
      console.log('Signed in anonymously');
      return userCredential.user;
    }
    console.log('User already signed in:', this.auth.currentUser.isAnonymous ? 'anonymously' : 'with account');
    return this.auth.currentUser;
  }

  async signInAnonymouslyIfNeeded(): Promise<User> {
    if (!this.auth.currentUser) {
      try {
        const userCredential = await signInAnonymously(this.auth);
        console.log('Signed in anonymously');
        return userCredential.user;
      } catch (error) {
        console.error('Error signing in anonymously:', error);
        throw error;
      }
    }
    console.log('User already signed in:', this.auth.currentUser.isAnonymous ? 'anonymously' : 'with account');
    return this.auth.currentUser;
  }
}

export const webFirebaseService = new WebFirebaseService();
export default webFirebaseService;