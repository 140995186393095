import { lazy } from 'react';
import { RouteConfig } from '../types/routeConfig';

// Define route types
// export interface RouteConfig {
//   path: string;
//   component: React.ComponentType<any>;
//   protected?: boolean;
//   adminOnly?: boolean;
// }

// Lazy load all components
export const routes: RouteConfig[] = [
  // Public routes
  { path: '/', component: lazy(() => import('../components/HomePage')) },
  { path: '/:date', component: lazy(() => import('../components/HomePage')) },
  { path: '/prayers', component: lazy(() => import('../components/catalog/PrayersCatalog')) },
  { path: '/signup', component: lazy(() => import('../components/authentication/AuthComponent')) },
  { path: '/login', component: lazy(() => import('../components/authentication/AuthComponent')) },
  { path: '/about', component: lazy(() => import('../components/about/AboutPage')) },
  { path: '/terms', component: lazy(() => import('../components/about/Terms')) },
  { path: '/logout-success', component: lazy(() => import('../components/authentication/LogoutSuccess')) },
  { path: '/saints', component: lazy(() => import('../components/saints/SaintsCatalog')) },
  { path: '/saints/:saintId', component: lazy(() => import('../components/saints/SaintMeditationContainer')) },
  { path: '/scripture', component: lazy(() => import('../components/scripture/CustomScripturePage')) },
  { path: '/scripture/meditation', component: lazy(() => import('../components/scripture/CustomScriptureMeditationContainer')) },
  { path: '/series', component: lazy(() => import('../components/series/SeriesCatalog')) },
  { path: '/series/:seriesId', component: lazy(() => import('../components/series/SeriesOverviewContainer')) },
  { path: '/series/:seriesId/items/:itemId', component: lazy(() => import('../components/series/SeriesItemContainer')) },
  { path: '/homily/:date?', component: lazy(() => import('../components/homilies/HomilyContainer')) },
  { path: '/gospel', component: lazy(() => import('../components/gospel/GospelPageContainer')) },

  // Prayer method routes
  { path: '/rosary/:mysteryType', component: lazy(() => import('../components/rosary/RosaryMeditationStyleSelector')) },
  { path: '/rosary/:mysteryType/:styleId', component: lazy(() => import('../components/rosary/RosaryMeditationContainer')) },
  { path: '/reflections/:methodLink', component: lazy(() => import('../components/daily-reflections/DailyReflectionContainer')) },
  { path: '/daily/:methodLink/:date?', component: lazy(() => import('../components/daily-prayers/DailyPrayerMethodContainer')) },
  { path: '/relaxation/:methodLink', component: lazy(() => import('../components/relaxation/RelaxationPrayerContainer')) },
  { path: '/contemplation/:methodLink', component: lazy(() => import('../components/contemplation/ContemplationMeditationContainer')) },

  // Protected routes
  { 
    path: '/advisor', 
    component: lazy(() => import('../components/advisor/RecommendationContainer')),
    protected: true 
  },
  { 
    path: '/advisor/plan', 
    component: lazy(() => import('../components/plan/PrayerPlan')),
    protected: true 
  },
  { 
    path: '/journey', 
    component: lazy(() => import('../components/journey/MyJourneyContainer')),
    protected: true 
  },
  { 
    path: '/account', 
    component: lazy(() => import('../components/authentication/AccountPage')),
    protected: true 
  },
  { 
    path: '/chat',
    component: lazy(() => import('../components/advisor-chat/AdvisorChatPage')),
    protected: true
  },

  // Admin routes
  { 
    path: '/admin/data-generation', 
    component: lazy(() => import('../components/admin/AdminPage')),
    protected: true,
    adminOnly: true 
  },
  { 
    path: '/admin/social-media-posts', 
    component: lazy(() => import('../components/admin/SocialMediaPosts')),
    protected: true,
    adminOnly: true 
  }
];

// Export route groups for easier management
export const publicRoutes = routes.filter(route => !route.protected && !route.adminOnly);
export const protectedRoutes = routes.filter(route => route.protected && !route.adminOnly);
export const adminRoutes = routes.filter(route => route.adminOnly);