import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { API_ENDPOINTS } from './apiConfig';
import { PrayerMethod, ScriptureData, AudioScript } from '../../types/customScripture';
import { PrayerActivityData } from '../../types/prayerHistory';
import { UserAnswers, RecommendationResponse, RecommendationHistoryItem } from '../../types/recommendation';
import { NextUpRecommendations, NextUpGospelRecommendation, NextUpSeriesRecommendation } from '../../types/nextUp';
import { ChatMessage, ChatSession, ChatResponse } from '../../types/advisorChat';

export const createApiMethods = (api: AxiosInstance) => ({
  // Generic HTTP Methods
  get: async <T>(url: string, config?: AxiosRequestConfig) => 
    api.get<T>(url, config),
    
  post: async <T>(url: string, data?: any, config?: AxiosRequestConfig) => 
    api.post<T>(url, data, config),
    
  put: async <T>(url: string, data?: any, config?: AxiosRequestConfig) => 
    api.put<T>(url, data, config),
    
  delete: async <T>(url: string, config?: AxiosRequestConfig) => 
    api.delete<T>(url, config),

  // Audio Generation Methods
  generateAudioPrayer: async (data: any) => 
    api.post(API_ENDPOINTS.GENERATE_AUDIO_PRAYER, data),
    
  generateAudioScript: async (data: any) =>
    api.post(API_ENDPOINTS.GENERATE_AUDIO_SCRIPT, data),
    
  generateDiscussionScript: async (data: {
    prayerMethod: PrayerMethod;
    gospelContent: DiscussionData;
    date: string;
  }) => api.post(API_ENDPOINTS.GENERATE_DISCUSSION_SCRIPT, data),
    
  generateDiscussionAudio: async (data: {
    prayerMethod: PrayerMethod;
    prayerData: DiscussionData;
    script: AudioScript[];
    date: string;
  }) => api.post(API_ENDPOINTS.GENERATE_DISCUSSION_AUDIO, data),
    
  generateRosaryAudio: async (data: any) =>
    api.post(API_ENDPOINTS.GENERATE_ROSARY_AUDIO, data),
    
  generateContemplationAudio: async (data: any) =>
    api.post(API_ENDPOINTS.GENERATE_CONTEMPLATION_AUDIO, data),

  // Social Media Methods
  generatePostingPlan: async (data: any) =>
    api.post(API_ENDPOINTS.GENERATE_POSTING_PLAN, data),

  // Stained Glass Methods
  generateStainedGlassImage: async (data: any) =>
    api.post(API_ENDPOINTS.GENERATE_STAINED_GLASS_IMAGE, data),
    
  saveStainedGlassImage: async (data: any) =>
    api.post(API_ENDPOINTS.SAVE_STAINED_GLASS_IMAGE, data),

  // Saint Methods
  generateSaintMeditation: async (data: any) =>
    api.post(API_ENDPOINTS.GENERATE_SAINT_MEDITATION, data),
    
  saveSaintData: async (data: any) =>
    api.post(API_ENDPOINTS.SAVE_SAINT_DATA, data),
    
  generateSaintImage: async (data: any) =>
    api.post(API_ENDPOINTS.GENERATE_SAINT_IMAGE, data),
    
  generateSaintMeditationAudio: async (data: any) =>
    api.post(API_ENDPOINTS.GENERATE_SAINT_MEDITATION_AUDIO, data),
    
  updateSaintData: async (data: any) =>
    api.put(API_ENDPOINTS.SAVE_SAINT_DATA, data),

  // Scripture Methods
  generateCustomScriptureMeditation: async (data: any) => 
    api.post(API_ENDPOINTS.CUSTOM_SCRIPTURE_MEDITATION, data),
    
  fetchBookContent: async (book: string) => 
    api.get(API_ENDPOINTS.FETCH_BOOK_CONTENT(book)),
    
  generateCustomMeditationScript: async (data: any) =>
    api.post(API_ENDPOINTS.GENERATE_CUSTOM_MEDITATION_SCRIPT, data),
    
  generateCustomMeditationAudio: async (data: any) =>
    api.post(API_ENDPOINTS.GENERATE_CUSTOM_MEDITATION_AUDIO, data),
    
  fetchCustomScripture: async (params: {
    book: string;
    startChapter: number;
    startVerse: number;
    endChapter: number;
    endVerse: number;
  }) => {
    const scripture = `${params.book} ${params.startChapter}:${params.startVerse}-${params.endChapter}:${params.endVerse}`;
    const response = await api.get(API_ENDPOINTS.FETCH_CUSTOM_SCRIPTURE, {
      params: { scripture }
    });
    return response.data;
  },

  // Prayer Activity Methods
  logPrayerActivity: async (data: {
    userId: string;
    prayerMethod: {
      id: string;
      type: string;
    };
    prayerData: PrayerActivityData;
  }) => api.post(API_ENDPOINTS.PRAYER_HISTORY.LOG, data),
    
  fetchPrayerHistory: async (userId: string) =>
    api.get(API_ENDPOINTS.PRAYER_HISTORY.GET(userId)),
    
  deletePrayerHistoryItem: async (userId: string, itemId: string) =>
    api.delete(API_ENDPOINTS.PRAYER_HISTORY.DELETE(userId, itemId)),

  // Recommendation Methods
  generateRecommendation: async (data: UserAnswers) =>
    api.post<RecommendationResponse>(API_ENDPOINTS.RECOMMENDATION.GENERATE, data),
    
  saveRecommendation: async (data: any) =>
    api.post(API_ENDPOINTS.RECOMMENDATION.SAVE, data),
    
  getRecommendationHistory: async (userId: string) =>
    api.get<RecommendationHistoryItem[]>(API_ENDPOINTS.RECOMMENDATION.GET_HISTORY(userId)),
    
  generateActivityBasedRecommendation: async (userId: string) =>
    api.get(API_ENDPOINTS.RECOMMENDATION.GENERATE_FROM_ACTIVITY(userId)),

  // Prayer Plan Methods
  fetchPrayerPlan: async (userId: string) =>
    api.get(API_ENDPOINTS.PRAYER_PLAN.GET(userId)),
    
  addToPrayerPlan: async (data: any) =>
    api.post(API_ENDPOINTS.PRAYER_PLAN.ADD, data),
    
  reorderPrayerPlan: async (userId: string, data: any) =>
    api.put(API_ENDPOINTS.PRAYER_PLAN.REORDER(userId), data),
    
  completePrayerPlanItem: async (userId: string, data: any) =>
    api.put(API_ENDPOINTS.PRAYER_PLAN.COMPLETE(userId), data),
    
  deletePrayerPlanItem: async (userId: string, itemId: string) =>
    api.delete(API_ENDPOINTS.PRAYER_PLAN.DELETE(userId, itemId)),

  // Series Progress Methods
  updateSeriesProgress: async (data: any) =>
    api.post(API_ENDPOINTS.SERIES_PROGRESS.UPDATE, data),
    
  getSeriesProgress: async (userId: string, seriesId: string) =>
    api.get(API_ENDPOINTS.SERIES_PROGRESS.GET(userId, seriesId)),
    
  listSeriesProgress: async (userId: string) =>
    api.get(API_ENDPOINTS.SERIES_PROGRESS.LIST(userId)),

  // Homily Methods
  generateHomily: async (data: any) =>
    api.post(API_ENDPOINTS.DAILY_HOMILIES.GENERATE, data),
    
  generateHomilyInspiration: async () =>
    api.post(API_ENDPOINTS.DAILY_HOMILIES.GENERATE_INSPIRATION),

  // Utility Methods
  fixScriptDurations: async (data: any) =>
    api.post(API_ENDPOINTS.FIX_SCRIPT_DURATIONS, data),

  // Next Up Methods
  getNextUpRecommendations: async (userId: string) =>
    api.get<NextUpRecommendations>(API_ENDPOINTS.NEXT_UP.GET_ALL(userId)),
    
  getNextUpGospel: async (userId: string) =>
    api.get<NextUpGospelRecommendation>(API_ENDPOINTS.NEXT_UP.GET_GOSPEL(userId)),
    
  getNextUpSeries: async (userId: string) =>
    api.get<NextUpSeriesRecommendation>(API_ENDPOINTS.NEXT_UP.GET_SERIES(userId)),

  // Advisor Chat Methods
  sendChatMessage: async (
    messages: { role: string; content: string }[], 
    userId: string,
    sessionId?: string
  ) => {
    const response = await api.post(
      API_ENDPOINTS.ADVISOR_CHAT.SEND_MESSAGE, 
      { messages, userId, sessionId }, 
      {
        responseType: 'text',
        headers: {
          'Accept': 'text/event-stream',
          'Cache-Control': 'no-cache',
        }
      }
    );

    // Convert response to ReadableStream
    const stream = new ReadableStream({
      start(controller) {
        // Split response into lines and process each line
        const lines = response.data.split('\n');
        
        for (const line of lines) {
          if (line.trim() && line.startsWith('data: ')) {
            try {
              const data = line.slice(6); // Remove 'data: ' prefix
              controller.enqueue(new TextEncoder().encode(data + '\n'));
            } catch (error) {
              console.error('Error processing line:', error);
            }
          }
        }
        
        controller.close();
      }
    });

    const reader = stream.getReader();
    if (!reader) throw new Error('No reader available');

    return reader;
  },
    
  getChatSessions: async (userId: string) => 
    api.get<ChatSession[]>(API_ENDPOINTS.ADVISOR_CHAT.GET_SESSIONS(userId)),

  getSessionMessages: async (userId: string, sessionId: string, params?: { 
    limit?: number;
    startAfter?: string;
  }) => 
    api.get<ChatMessage[]>(
      API_ENDPOINTS.ADVISOR_CHAT.GET_SESSION_MESSAGES(userId, sessionId), 
      { params }
    ),

  clearChatSession: async (userId: string, sessionId: string) =>
    api.delete(API_ENDPOINTS.ADVISOR_CHAT.DELETE_SESSION(userId, sessionId))
});

export type ApiMethods = ReturnType<typeof createApiMethods>; 