import axios, { AxiosInstance, InternalAxiosRequestConfig } from 'axios';
import { BaseFirebaseService } from '../firebase/base';
import { API_ENDPOINTS } from './apiConfig';

export const getApiBaseUrl = (): string => {
  if (process.env.REACT_APP_NODE_ENV === 'development') {
    if (typeof window !== 'undefined' && window.location.hostname === 'localhost') {
      return `http://localhost:5001/${process.env.REACT_APP_DEV_FIREBASE_PROJECT_ID}/us-central1/api`;
    }
    return `https://us-central1-${process.env.REACT_APP_DEV_FIREBASE_PROJECT_ID}.cloudfunctions.net/api`;
  }
  if (process.env.REACT_APP_NODE_ENV === 'production') {
    if (typeof window !== 'undefined' && window.location.hostname === 'localhost' && process.env.REACT_APP_USE_EMULATOR_FOR_LOCAL_DEV === 'TRUE') {
      return `http://localhost:5001/${process.env.REACT_APP_FIREBASE_PROJECT_ID}/us-central1/api`;
    }
    return process.env.REACT_APP_FIREBASE_FUNCTIONS_URL || '';
  }
  return '';
};

export const createApi = (firebaseService: BaseFirebaseService): AxiosInstance => {
  const api = axios.create({
    baseURL: getApiBaseUrl(),
  });

  api.interceptors.request.use(
    async (config: InternalAxiosRequestConfig) => {
      const token = await firebaseService.getAuthToken();
      if (token && config.headers) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  return api;
}; 