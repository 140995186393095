interface ApiEndpoints {
    GENERATE_AUDIO_PRAYER: string;
    GENERATE_AUDIO_SCRIPT: string;
    GENERATE_ROSARY_AUDIO: string;
    GENERATE_DISCUSSION_SCRIPT: string;
    GENERATE_DISCUSSION_AUDIO: string;
    FETCH_GOSPEL_READING: (date: string) => string;
    FETCH_USCCB_GOSPEL: string;
    FETCH_GOSPEL_ANALYSIS: (date: string) => string;
    PRAYER_DATA: string;
    GENERIC_PRAYER: (methodApiLink: string, date: string) => string;
    GENERATE_CONTEMPLATION_AUDIO: string;
    GENERATE_SOCIAL_MEDIA_POST: string;
    GENERATE_POSTING_PLAN: string;
    SCHEDULE_SOCIAL_MEDIA_POST: string;
    GENERATE_STAINED_GLASS_IMAGE: string;
    SAVE_STAINED_GLASS_IMAGE: string;
    GENERATE_SAINT_MEDITATION: string;
    SAVE_SAINT_DATA: string;
    GENERATE_SAINT_IMAGE: string;
    SAINT_AUDIO_MEDITATIONS: (saintId: string, stepIndex: number) => string;
    GENERATE_SAINT_MEDITATION_AUDIO: string;
    UPDATE_SAINT_DATA: string;
    FETCH_BOOK_CONTENT: (book: string) => string;
    CUSTOM_SCRIPTURE_MEDITATION: string;
    FETCH_CUSTOM_SCRIPTURE: string;
    GENERATE_CUSTOM_MEDITATION_SCRIPT: string;
    GENERATE_CUSTOM_MEDITATION_AUDIO: string;
    GENERATE_RECOMMENDATION: string;
    GENERATE_RECOMMENDATION_FROM_ACTIVITY: (userId: string) => string;
    PRAYER_HISTORY: {
      LOG: string;
      GET: (userId: string) => string;
      DELETE: (userId: string, prayerId: string) => string;
    };
    RECOMMENDATION: {
      GENERATE: string;
      GENERATE_FROM_ACTIVITY: (userId: string) => string;
      SAVE: string;
      GET_HISTORY: (userId: string) => string;
    };
    PRAYER_PLAN: {
      GET: (userId: string) => string;
      ADD: string;
      REORDER: (userId: string) => string;
      COMPLETE: (userId: string) => string;
      DELETE: (userId: string, itemId: string) => string;
    };
    SERIES_PROGRESS: {
      UPDATE: string;
      GET: (userId: string, seriesId: string) => string;
      LIST: (userId: string) => string;
    };
    DAILY_HOMILIES: {
      GET: (date: string) => string;
      GENERATE: string;
      GENERATE_WITH_STYLE: (styleId: string) => string;
      GENERATE_INSPIRATION: string;
    };
    FIX_SCRIPT_DURATIONS: string;
    NEXT_UP: {
      GET_ALL: (userId: string) => string;
      GET_GOSPEL: (userId: string) => string;
      GET_SERIES: (userId: string) => string;
      GET_PRAYER_PLAN: (userId: string) => string;
      GET_PERSONALIZED: (userId: string) => string;
    };
    ADVISOR_CHAT: {
      SEND_MESSAGE: string;
      GET_SESSIONS: (userId: string) => string;
      GET_SESSION_MESSAGES: (userId: string, sessionId: string) => string;
      DELETE_SESSION: (userId: string, sessionId: string) => string;
      GET_HISTORY: string;
      CLEAR_CHAT: string;
    };
  }
  
  interface StoragePaths {
    DAILY_AUDIO_PRAYERS: (prayerMethodId: string, date: string) => string;
    RELAXATION_AUDIO_PRAYERS: (prayerMethodId: string) => string;
    ROSARY_AUDIO_MEDITATIONS: (rosaryMeditationId: string, mysteryId: string) => string;
    DAILY_AUDIO_DISCUSSIONS: (prayerMethodId: string, date: string) => string;
    CONTEMPLATION_AUDIO_MEDITATIONS: (prayerMethodId: string) => string;
    DAILY_REFLECTIONS_AUDIO: (prayerMethodId: string, stepIndex: number) => string;
    SAINT_AUDIO_MEDITATIONS: (saintId: string) => string;
    SAINT_AUDIO_DISCUSSIONS: (saintId: string) => string;
    CUSTOM_SCRIPTURE_AUDIO_MEDITATIONS: (prayerMethodId: string, citation: string) => string;
  }
  
  interface FirestorePaths {
    DAILY_GOSPEL_READINGS: {
      collection: string;
      doc: (date: string) => string;
      path: (date: string) => string;
    };
    PRAYER_METHODS: {
      collection: string;
      doc: (prayerMethodId: string) => string;
      path: (prayerMethodId: string) => string;
    };
    DAILY_PRAYERS_TEXT: {
      collection: string;
      doc: (prayerMethodId: string, date: string) => string;
      path: (prayerMethodId: string, date: string) => string;
    };
    DAILY_PRAYERS_SCRIPT: {
      collection: string;
      doc: (prayerMethodId: string, date: string) => string;
      path: (prayerMethodId: string, date: string) => string;
    };
    RELAXATION_PRAYERS: {
      collection: string;
      doc: (prayerMethodId: string) => string;
      path: (prayerMethodId: string) => string;
    };
    DAILY_DISCUSSIONS: {
      collection: string;
      doc: (prayerMethodId: string, date: string) => string;
      path: (prayerMethodId: string, date: string) => string;
    };
    ROSARY_MEDITATIONS: {
      collection: string;
      doc: (prayerMethodId: string, rosaryMeditationStyleId: string) => string;
      path: (prayerMethodId: string, rosaryMeditationStyleId: string) => string;
    };
    DAILY_REFLECTIONS: {
      collection: string;
      doc: (prayerMethodId: string) => string;
      path: (prayerMethodId: string) => string;
    };
    CONTEMPLATION_MEDITATIONS: {
      collection: string;
      doc: (prayerMethodId: string) => string;
      path: (prayerMethodId: string) => string;
    };
    USERS: {
      collection: string;
      doc: (userId: string) => string;
      path: (userId: string) => string;
    };
    SAINTS_DATA: {
      collection: string;
      doc: (saintId: string) => string;
      path: (saintId: string) => string;
    };
    SAINT_MEDITATIONS: {
      collection: string;
      doc: (saintId: string) => string;
      path: (saintId: string) => string;
    };
    CUSTOM_MEDITATION_TEXT: {
      collection: string;
      doc: (prayerMethodId: string, citation: string) => string;
      path: (prayerMethodId: string, citation: string) => string;
    };
    CUSTOM_MEDITATION_SCRIPTS: {
      collection: string;
      doc: (prayerMethodId: string, citation: string) => string;
      path: (prayerMethodId: string, citation: string) => string;
    };
    DAILY_HOMILIES: {
      collection: string;
      doc: (date: string) => string;
      path: (date: string) => string;
    };
    SERIES_CATALOG: {
      collection: string;
      doc: (seriesId: string) => string;
      path: (seriesId: string) => string;
    };
    SERIES_DETAILS: {
      collection: string;
      doc: (seriesId: string) => string;
      path: (seriesId: string) => string;
    };
    SERIES_PROGRESS: {
      collection: string;
      doc: (userId: string, seriesId: string) => string;
      path: (userId: string, seriesId: string) => string;
    };
  }
  
  export const API_ENDPOINTS: ApiEndpoints = {
    GENERATE_AUDIO_PRAYER: '/generate-audio-prayer',
    GENERATE_AUDIO_SCRIPT: '/generate-audio-script',
    GENERATE_ROSARY_AUDIO: '/generate-rosary-audio',
    GENERATE_DISCUSSION_SCRIPT: '/generate-discussion-script',
    GENERATE_DISCUSSION_AUDIO: '/generate-discussion-audio',
    FETCH_GOSPEL_READING: (date) => `/gospel/${date}`,
    FETCH_USCCB_GOSPEL: '/usccb-gospel',
    FETCH_GOSPEL_ANALYSIS: (date) => `/gospel-analysis/${date}`,
    PRAYER_DATA: '/prayer-data',
    GENERIC_PRAYER: (methodApiLink, date) => `/${methodApiLink}/${date}`,
    GENERATE_CONTEMPLATION_AUDIO: '/generate-contemplation-audio',
    GENERATE_SOCIAL_MEDIA_POST: '/generate-social-media-post',
    GENERATE_POSTING_PLAN: '/generate-posting-plan',
    SCHEDULE_SOCIAL_MEDIA_POST: '/schedule-social-media-post',
    GENERATE_STAINED_GLASS_IMAGE: '/generate-stained-glass-image',
    SAVE_STAINED_GLASS_IMAGE: '/save-stained-glass-image',
    GENERATE_SAINT_MEDITATION: '/generate-saint-meditation',
    SAVE_SAINT_DATA: '/save-saint-data',
    GENERATE_SAINT_IMAGE: '/generate-saint-image',
    SAINT_AUDIO_MEDITATIONS: (saintId, stepIndex) => `/audio/saint-meditations/${saintId}/step_${stepIndex}`,
    GENERATE_SAINT_MEDITATION_AUDIO: '/generate-saint-meditation-audio',
    UPDATE_SAINT_DATA: '/update-saint-data',
    FETCH_BOOK_CONTENT: (book) => `/custom-scripture/book/${encodeURIComponent(book)}`,
    CUSTOM_SCRIPTURE_MEDITATION: '/custom-scripture-meditation',
    FETCH_CUSTOM_SCRIPTURE: '/custom-scripture',
    GENERATE_CUSTOM_MEDITATION_SCRIPT: '/generate-custom-meditation-script',
    GENERATE_CUSTOM_MEDITATION_AUDIO: '/generate-custom-meditation-audio',
    GENERATE_RECOMMENDATION: '/generate-recommendation',
    GENERATE_RECOMMENDATION_FROM_ACTIVITY: (userId) => `/generate-recommendation/${userId}/from-activity`,
    PRAYER_HISTORY: {
      LOG: '/prayer-history/log',
      GET: (userId) => `/prayer-history/${userId}`,
      DELETE: (userId, prayerId) => `/prayer-history/${userId}/${prayerId}`,
    },
    RECOMMENDATION: {
      GENERATE: '/generate-recommendation',
      GENERATE_FROM_ACTIVITY: (userId) => `/generate-recommendation/${userId}/from-activity`,
      SAVE: '/recommendation/save',
      GET_HISTORY: (userId) => `/recommendation/${userId}`,
    },
    PRAYER_PLAN: {
      GET: (userId) => `/prayer-plan/${userId}`,
      ADD: '/prayer-plan/add',
      REORDER: (userId) => `/prayer-plan/${userId}/reorder`,
      COMPLETE: (userId) => `/prayer-plan/${userId}/complete`,
      DELETE: (userId, itemId) => `/prayer-plan/${userId}/${itemId}`,
    },
    SERIES_PROGRESS: {
      UPDATE: '/series-progress/update',
      GET: (userId, seriesId) => `/series-progress/${userId}/${seriesId}`,
      LIST: (userId) => `/series-progress/${userId}`,
    },
    DAILY_HOMILIES: {
      GET: (date) => `/homilies/${date}`,
      GENERATE: '/generate-homily',
      GENERATE_WITH_STYLE: (styleId) => `/generate-homily/${styleId}`,
      GENERATE_INSPIRATION: '/generate-homily-inspiration',
    },
    FIX_SCRIPT_DURATIONS: '/fix-script-durations',
    NEXT_UP: {
      GET_ALL: (userId) => `/next-up/${userId}`,
      GET_GOSPEL: (userId) => `/next-up/${userId}/gospel`,
      GET_SERIES: (userId) => `/next-up/${userId}/series`,
      GET_PRAYER_PLAN: (userId) => `/next-up/${userId}/prayer-plan`,
      GET_PERSONALIZED: (userId) => `/next-up/${userId}/personalized`,
    },
    ADVISOR_CHAT: {
      SEND_MESSAGE: '/advisor-chat/message',
      GET_SESSIONS: (userId: string) => `/advisor-chat/${userId}/sessions`,
      GET_SESSION_MESSAGES: (userId: string, sessionId: string) => `/advisor-chat/${userId}/sessions/${sessionId}/messages`,
      DELETE_SESSION: (userId: string, sessionId: string) => `/advisor-chat/${userId}/sessions/${sessionId}`,
      GET_HISTORY: '/advisor-chat/history',
      CLEAR_CHAT: '/advisor-chat/clear'
    }
  };
  
  export const STORAGE_PATHS: StoragePaths = {
    DAILY_AUDIO_PRAYERS: (prayerMethodId, date) => `/audio/daily-prayers/${prayerMethodId}/${date}`,
    RELAXATION_AUDIO_PRAYERS: (prayerMethodId) => `/audio/relaxation-prayers/${prayerMethodId}`,
    ROSARY_AUDIO_MEDITATIONS: (rosaryMeditationId, mysteryId) => `/audio/rosary-meditations/${rosaryMeditationId}/${mysteryId}`,
    DAILY_AUDIO_DISCUSSIONS: (prayerMethodId, date) => `/audio/daily-discussions/${prayerMethodId}/${date}`,
    CONTEMPLATION_AUDIO_MEDITATIONS: (prayerMethodId) => `/audio/contemplation-meditations/${prayerMethodId}`,
    DAILY_REFLECTIONS_AUDIO: (prayerMethodId, stepIndex) => `/audio/daily-reflections/${prayerMethodId}/step_${stepIndex}`,
    SAINT_AUDIO_MEDITATIONS: (saintId) => `/audio/saint-meditations/${saintId}/meditation`,
    SAINT_AUDIO_DISCUSSIONS: (saintId) => `/audio/saint-meditations/${saintId}/discussion`,
    CUSTOM_SCRIPTURE_AUDIO_MEDITATIONS: (prayerMethodId, citation) => 
      `audio/custom_meditations/${prayerMethodId}/${encodeURIComponent(citation.replace(/\s+/g, '_'))}`,
  };
  
  export const FIRESTORE_PATHS: FirestorePaths = {
    DAILY_GOSPEL_READINGS: {
      collection: 'gospelReadings',
      doc: (date) => date,
      path: (date) => `gospelReadings/${date}`,
    },
    PRAYER_METHODS: {
      collection: 'prayerMethods',
      doc: (prayerMethodId) => prayerMethodId,
      path: (prayerMethodId) => `prayerMethods/${prayerMethodId}`,
    },
    DAILY_PRAYERS_TEXT: {
      collection: 'dailyPrayers',
      doc: (prayerMethodId, date) => `${prayerMethodId}/${date}/text_prayer`,
      path: (prayerMethodId, date) => `dailyPrayers/${prayerMethodId}/${date}/text_prayer`,
    },
    DAILY_PRAYERS_SCRIPT: {
      collection: 'dailyPrayers',
      doc: (prayerMethodId, date) => `${prayerMethodId}/${date}/audio_prayer`,
      path: (prayerMethodId, date) => `dailyPrayers/${prayerMethodId}/${date}/audio_prayer`,
    },
    RELAXATION_PRAYERS: {
      collection: 'relaxationPrayers',
      doc: (prayerMethodId) => prayerMethodId,
      path: (prayerMethodId) => `relaxationPrayers/${prayerMethodId}`,
    },
    DAILY_DISCUSSIONS: {
      collection: 'dailyDiscussions',
      doc: (prayerMethodId, date) => `${prayerMethodId}/${date}/audio_discussion`,
      path: (prayerMethodId, date) => `dailyDiscussions/${prayerMethodId}/${date}/audio_discussion`,
    },
    ROSARY_MEDITATIONS: {
      collection: 'rosaryMeditations',
      doc: (prayerMethodId, rosaryMeditationStyleId) => `${prayerMethodId}_${rosaryMeditationStyleId}`,
      path: (prayerMethodId, rosaryMeditationStyleId) => `rosaryMeditations/${prayerMethodId}_${rosaryMeditationStyleId}`,
    },
    DAILY_REFLECTIONS: {
      collection: 'dailyReflections',
      doc: (prayerMethodId) => prayerMethodId,
      path: (prayerMethodId) => `dailyReflections/${prayerMethodId}`,
    },
    CONTEMPLATION_MEDITATIONS: {
      collection: 'contemplationMeditations',
      doc: (prayerMethodId) => prayerMethodId,
      path: (prayerMethodId) => `contemplationMeditations/${prayerMethodId}`,
    },
    USERS: {
      collection: 'users',
      doc: (userId) => userId,
      path: (userId) => `users/${userId}`,
    },
    SAINTS_DATA: {
      collection: 'saintsData',
      doc: (saintId) => saintId,
      path: (saintId) => `saintsData/${saintId}`,
    },
    SAINT_MEDITATIONS: {
      collection: 'saintMeditations',
      doc: (saintId) => saintId,
      path: (saintId) => `saintMeditations/${saintId}`,
    },
    CUSTOM_MEDITATION_TEXT: {
      collection: 'customMeditations',
      doc: (prayerMethodId, citation) => `${prayerMethodId}/${encodeURIComponent(citation.replace(/\s+/g, '_'))}`,
      path: (prayerMethodId, citation) => `customMeditations/${prayerMethodId}/${encodeURIComponent(citation.replace(/\s+/g, '_'))}/text`,
    },
    CUSTOM_MEDITATION_SCRIPTS: {
      collection: 'customMeditations',
      doc: (prayerMethodId, citation) => `${prayerMethodId}/${encodeURIComponent(citation.replace(/\s+/g, '_'))}`,
      path: (prayerMethodId, citation) => `customMeditations/${prayerMethodId}/${encodeURIComponent(citation.replace(/\s+/g, '_'))}/audio_script`,
    },
    DAILY_HOMILIES: {
      collection: 'dailyHomilies',
      doc: (date) => date,
      path: (date) => `dailyHomilies/${date}`,
    },
    SERIES_CATALOG: {
      collection: 'seriesCatalog',
      doc: (seriesId) => seriesId,
      path: (seriesId) => `seriesCatalog/${seriesId}`,
    },
    SERIES_DETAILS: {
      collection: 'seriesDetails',
      doc: (seriesId) => seriesId,
      path: (seriesId) => `seriesDetails/${seriesId}`,
    },
    SERIES_PROGRESS: {
      collection: 'userActivity',
      doc: (userId: string, seriesId: string) => `${userId}/seriesProgress/${seriesId}`,
      path: (userId: string, seriesId: string) => `userActivity/${userId}/seriesProgress/${seriesId}`
    }
  };