import { AuthError } from 'firebase/auth';

interface RateLimitEntry {
  attempts: number;
  lastAttempt: number;
}

export class RateLimitError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'RateLimitError';
  }
}

const RATE_LIMIT_WINDOW = 15 * 60 * 1000; // 15 minutes
const MAX_ATTEMPTS = 5;

const rateLimitStore = new Map<string, RateLimitEntry>();

export const checkRateLimit = (identifier: string): void => {
  const now = Date.now();
  const entry = rateLimitStore.get(identifier);

  if (entry) {
    // Reset if outside window
    if (now - entry.lastAttempt > RATE_LIMIT_WINDOW) {
      rateLimitStore.set(identifier, { attempts: 1, lastAttempt: now });
      return;
    }

    if (entry.attempts >= MAX_ATTEMPTS) {
      throw new RateLimitError('TOO_MANY_ATTEMPTS');
    }

    entry.attempts += 1;
    entry.lastAttempt = now;
    rateLimitStore.set(identifier, entry);
  } else {
    rateLimitStore.set(identifier, { attempts: 1, lastAttempt: now });
  }
};
